import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { auth } from "../../../firebase/firebase";

// Icons
import { MdHome, MdPowerSettingsNew, MdTopic, MdMenuBook, MdHelp, MdPerson, MdOutlineVideoLibrary /*, MdMonetizationOn */ } from "react-icons/md";
import { HiHeart } from "react-icons/hi";
import { FaCheckCircle } from "react-icons/fa";

// Assets
import theme from "../../../assets/nav/theme.png";

type DrawerItemProps = {
    to: string;
    onClick?: any;
    Icon?: React.FC;
    primaryText: string;
    color?: string;
    subTitle?: boolean;
};

const DrawerItem: React.FC<DrawerItemProps> = ({ to, onClick, Icon, primaryText, color }) => {
    return (
        <NavLink to={to} onClick={() => onClick ?? null} className="drawer__nav__item">
            <ListItem button>
                <ListItemIcon>{Icon ? <Icon /> : null}</ListItemIcon>
                <ListItemText primary={primaryText} color={color ?? "primary"} />
            </ListItem>
        </NavLink>
    );
};

const DrawerContent = () => {
    const { t } = useTranslation();
    const { currentWeek, currentParticipant, resetStates } = useFetchUser();

    // toggle theme
    const changeTheme = () => {
        const divColor = (document.getElementById("color") as any).classList;
        if (divColor.contains("normal")) {
            divColor.replace("normal", "inverse");
            localStorage.setItem("theme", "inverse");
        } else {
            divColor.replace("inverse", "normal");
            localStorage.setItem("theme", "normal");
        }
    };

    const handleLogout = () => {
        // Clear stored state
        localStorage.removeItem('userStudyContext');
        localStorage.removeItem('authToken');
        document.cookie = "isLoggedOut=true; path=/; domain=.brainhealthpro.ca;";
        resetStates();
        auth.signOut();      
    };

    return (
        <div className="portal">
            <div className="drawer__nav">
                <List>
                    <DrawerItem to="/home" Icon={MdHome} primaryText={t("home")} />
                    {/* <DrawerItem to="/formtest" Icon={MdMonetizationOn} primaryText={t("formtest")} /> */}
                    {currentWeek >= 3 && currentParticipant && (
                        <>
                            <DrawerItem to="/profile" Icon={MdPerson} primaryText={t("profile")} />
                            <DrawerItem to="/topics" Icon={MdTopic} primaryText={t("topics")} />

                            {/* <NavLink
                                to={"/favouritesTopics"}
                                className="drawer__nav__subitem"
                                style={{ fontSize: 15, paddingLeft: 50, paddingTop: 0 }}
                            >
                                <ListItem button>
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                        <HiHeart />
                                    </ListItemIcon>
                                    <ListItemText primary={t("favourites")} color={"favourites"} style={{ fontSize: 15 }} />
                                </ListItem>
                            </NavLink>
                            <NavLink to={"/completedTopics"} className="drawer__nav__subitem" style={{ fontSize: 15, paddingLeft: 50 }}>
                                <ListItem button>
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                        <FaCheckCircle />
                                    </ListItemIcon>
                                    <ListItemText primary={t("completed")} color={"favourites"} />
                                </ListItem>
                            </NavLink> */}

                            <DrawerItem to="/favouritesTopics" Icon={HiHeart} primaryText={t("favourites")} />
                        </>
                    )}
                    {currentWeek >= 2 && <DrawerItem to="/completedTopics" Icon={FaCheckCircle} primaryText={t("completed")} />}
                    {currentParticipant?.hasLibraryAccess && <DrawerItem to="/library" Icon={MdOutlineVideoLibrary} primaryText={t("library")} />}
                    {currentWeek >= 2 && <DrawerItem to="/notebook" Icon={MdMenuBook} primaryText={t("notebook")} />}
                    <DrawerItem to="/help" Icon={MdHelp} primaryText={t("help")} />
                </List>
                <Divider />
                <List>
                    <div
                        className="drawer__nav__item"
                        onClick={handleLogout}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <MdPowerSettingsNew />
                            </ListItemIcon>
                            <ListItemText primary={t("logout")} color="primary" />
                        </ListItem>
                    </div>
                </List>
                <div className="nav__content__theme nav__theme__drawer" style={{ marginLeft: "6px" }}>
                    <Button onClick={changeTheme} size="medium">
                        <img src={theme} alt="" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DrawerContent;
