import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { browserLocalPersistence, setPersistence, signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { useLocation } from "react-router-dom";

const SubDomainSignin = () => {
    const query = useQuery();
    const { pathname } = useLocation();

    useEffect(() => {
        const signin = async () => {
            try {
                const tokenQuery = query.get("token");
                if (tokenQuery) {
                    await setPersistence(auth, browserLocalPersistence);
                    await signInWithCustomToken(auth, tokenQuery);
                    window.history.replaceState({}, document.title, window.location.pathname);
                } else {
                    if (window.location.href.includes("syn2.brainhealthpro.ca/login")) {
                        window.location.href = "https://brainhealthpro.ca/login";
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        signin();
    }, [query, pathname]);

    return <></>;
};

export default SubDomainSignin;
